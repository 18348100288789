.walkthroughBox{display: flex;grid-gap: 24px;flex-direction: column;align-items: center;}
.vrBigBox{display: flex; gap: 24px;}
.vrBigTiles{position: relative; border-radius: 5px; overflow: hidden; cursor: pointer;}
.vrBigTiles figure>img{max-width: 100%;max-height: 280px;}
.vrBoxuperimg{position: absolute;top: 20px;left: 20px;}
.vrbigLtextbox{position: absolute;bottom: 0;left: 0;right: 0;padding: 20px;background-color: rgba(0, 0, 0, 0.5);}


@media screen and (max-width: 540px){
	.vrBigBox{flex-direction: column;gap: 15px;}
}